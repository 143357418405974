import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../../App";
import { Container, Paper } from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import TermAndCondition from "components/atoms/TermAndCondition";
import LeafBox from "components/atoms/LeafBox";
import { scrollToTop } from '../../../../utility/functions';

// Import SVG Icons.
import { LHSeniorIcon, LHMilitaryIcon, LHNeitherIcon } from "components/atoms/Icons";
import { LeafButton } from "components/atoms/LeafButtons";
import { useComponentName, useProduct } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";

const Step2 = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const product = useProduct();
  const formRef = useRef(null);

  const { activeLHStepIndex, setActiveLHStepIndex, formData, setFormData } =
    useContext(FormContext);

  let groupList = [];
  const handleImageClick = (value, setFieldValue) => {
    if (value !== 'neither') {
      const groupListIndex = groupList.indexOf(value);
      if (groupListIndex > -1) {
        groupList.splice(groupListIndex, 1);
      }
      else {
        groupList.push(value);
      }
      setFieldValue("groupLevel", groupList);
    }
    else {
      groupList.splice(0, groupList.length);
      setFieldValue("groupLevel", value);
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)}`,
    borderRadius: 8,
    width: "100%",
    height: "auto",
  }));

  return (
    <Formik
      initialValues={{
        groupLevel: "",
      }}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setActiveLHStepIndex(activeLHStepIndex + 1);

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 2;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 3;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, errors, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              className="flex flex-col justify-center items-start"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <div className="step2">
                <h3 className="fw-normal mb-3">
                  Let’s see if we can find you some discounts. Are you a senior, active service member or veteran?
                </h3>
              </div>

              <LeafBox>
                <label className="font-medium text-green-600 small mb-3">
                  {/* Which of these applies?{" "}
                  <span className="optional">(optional)</span> */}
                  Qualifying members will get 10% off their purchase.
                </label>
                <Field name="groupLevel">
                  {() => (
                    <LeafBox>
                      <LHMilitaryIcon handleClick={() =>
                        handleImageClick("military", setFieldValue)
                      } />
                      <LHSeniorIcon handleClick={() =>
                        handleImageClick("senior", setFieldValue)
                      } />
                      <LHNeitherIcon handleClick={() =>
                        handleImageClick("neither", setFieldValue)
                      } />
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>
              <Divider className="divider" />
              <LeafButton buttonClass='rounded-3 btn-primary w-100 fw-semibold' type="submit" style={{
                width: "100%"
              }}>
                Next
              </LeafButton>
              <ProgressBar />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default Step2;
