import React, { useContext, createContext, useEffect, useState } from "react";
import { FormContext } from "../../../../App";
import Step1 from '../MultiStep-Estimate/Step1';
import Step2 from '../MultiStep-Estimate/Step2';
import Step3 from '../MultiStep-Estimate/Step3';
import Step4 from '../MultiStep-Estimate/Step4';
import TrustedFormScript from "components/atoms/TrustedFormScript";
import ThankYou from './ThankYou';
import ThankYouB from './ThankYouB';
import ThankYouC from './ThankYouC';
import ThankYouRenter from "components/atoms/ThankYouRenter";
export const FormContextSelfSchedulerStepper = createContext();

const SelfScheduler = (props) => {
  const { activeLHStepIndex, setActiveLHStepIndex, setVLeafhome } = useContext(FormContext);
  const thankYouSection = props.dataSet?.thankyoumessage ? props.dataSet.thankyoumessage : '';
  const [ marketoData, setMarketoData ] = useState({})
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  useEffect(() => {
    setVLeafhome(leahomeVersion);
  });

  let stepContent;
  switch (activeLHStepIndex) {
    case 0:
      stepContent = <Step1 {...props} />;
      break;

    case 1:
      stepContent = <Step2 {...props} />;
      break;

    case 2:
      stepContent = <Step3 {...props} />;
      break;

    case 3:
      stepContent = <Step4 {...props} />;
    break;

    case 4:
      switch (thankYouSection) {
        case 'test-B':
          stepContent = <ThankYouB {...props} />;
          break;

        case 'test-C':
          stepContent = <ThankYouC {...props} />;
          break;

        default:
          stepContent = <ThankYou {...props} />;
        break;
      }
    break;
    case 'renter':
      stepContent = <ThankYouRenter activeStep={setActiveLHStepIndex}  {...props} />;
    break;

    default:
      break;
  }

  return (
    <FormContextSelfSchedulerStepper.Provider value={{marketoData, setMarketoData}}>
      <div className="leafhome-forms leaf-forms">{stepContent}</div>
      <TrustedFormScript />
    </FormContextSelfSchedulerStepper.Provider>
  );
}

export default SelfScheduler;
