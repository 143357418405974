import { Form, Formik, Field } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../../App";
import * as yup from "yup";
import { Container, Paper, styled, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import "./style.scss";
import PostService from "utility/postService";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";
import SMSMarketingCampaignComponent from "components/atoms/SMSMarketingCampaign";
import TermAndConditionLHWS from "components/atoms/TermAndConditionLHWS";
import EmailInput from "components/atoms/EmailInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import TextInput from "components/atoms/TextInput";
import EstimateSourceSelectLHWS from "components/atoms/EstimateSourceSelectLHWS";
import WaterTypeSelect from "components/atoms/WaterTypeSelect";
import { LeafButton } from "components/atoms/LeafButtons";
import { useProduct, useReCaptchaScore, useComponentName } from "hooks";
import ReCAPTCHA from "react-google-recaptcha";
import { getactiveTestScriptAP, scrollToTop, modalScrollToTop } from '../../../../utility/functions';

const LHWSSingleStepEstimate = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const zipSource = props.dataSet?.zipsource || "localzip";
  const discountText = props.dataSet?.discount ? props.dataSet.discount : "";
  const activeProspectTestScript = getactiveTestScriptAP()
  let errorMsg = "";
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  let zipCodeValidationStatus = 'invalid';
  const formRef = useRef(null);

  let emailAddress = '';
  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  let captchaScore = null;

  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  const buttonSubmit = (e) => {
    const EmailValidation = Validation.EmailDataLayerValidation(emailAddress);

    let zipCodeValidationError = {};
    if (!formData.zipCode) {
       zipCodeValidationError = Validation.ZipValidationCodeDataLayerValidation(zipCode, zipCodeValidation.validationPattern);
    }

    // Validation Error.
    const validationError = {
      ...EmailValidation,
      ...zipCodeValidationError
    };

    if (Object.keys(validationError).length !== 0) {
      DataLayerService.formFailEvent(form, location, validationError);

      modalScrollToTop(props.modalComponent);
    }
  }

  const handleOnBlur = (event) => {

    switch (event.target.name) {
      case 'emailAddress':
        emailAddress = event.target.value;
      break;

      case 'zipCode':
        zipCode = event.target.value;
      break;

      default:
      break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const { activeStepIndex, setActiveStepIndex, formData } =
    useContext(FormContext);

  const ValidationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`)
      .test('available-zipcode', zipCodeValidation.restrictedValidationMessage, async function (zipcode) {
        if (!(formData.zipCode) && (zipcodeInputValue !== zipcode) && (zipCodeValidation.validationPattern.test(zipcode))) {
          serviceAreaFlag = await PostService.checkAvailableService(zipcode, product, zipSource);
          zipcodeInputValue = zipcode;
          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if (!serviceAreaFlag) {
            DataLayerService.formFailEvent(form, location, {'zipCode': ['OOA restriction for ' + zipcode + ' failed.']});
          }
          if (userJourneyTracking) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcodeInputValue, 'completed', zipCodeValidationStatus);
          }
          // Return true if the validation passes, false if it fails
          return serviceAreaFlag;
        }
        else {
          if (formData.zipCode) {
            return true;
          }

          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if ((userJourneyTracking) && formData.zipCode !== null) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipCode, 'completed', zipCodeValidationStatus);
          }
          return serviceAreaFlag;
        }
      }),
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
    firstName: yup.string().required("First Name is required."),
    lastName: yup.string().required("Last Name is required."),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const recaptchaRef = useRef();
  const reCaptchaScoreConstant = useReCaptchaScore();

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        zipCode: formData.zipCode
        ? formData.zipCode
        : localStorage.getItem("zipCode") || "",
        selectedOption: "",
        termsOfService: false,
        trustedCertURL: document.getElementsByName("xxTrustedFormCertUrl")[0]
        ? document.getElementsByName("xxTrustedFormCertUrl")[0].value
        : "",
      }}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values, actions) => {

        // Validate if the user is not a Robot.
        let captchaValue = '';
        const RecaptchaVerification = await Validation.RecaptchaVerification();
        captchaScore = RecaptchaVerification.score;
        if (captchaScore < reCaptchaScoreConstant) {
          captchaValue = recaptchaRef.current.getValue();
        }
        else {
          captchaValue = RecaptchaVerification.token;
        }

        if (captchaValue || !reCaptchaShow) {
          const data = { ...formData, ...values };
          const comments = "txtSMSMarketingConsent:" + (values.txtSMSMarketingConsent ? true : false) +
          "\nTrusted Form URL:" + values.trustedCertURL + "\nRecaptcha Score:" + captchaScore;

          let submittedValues = {
            'web_lead_source' : "",
            'country': 'US',
            'emailAddress': values.emailAddress,
            'firstName' : values.firstName,
            'lastName': values.lastName,
            'phoneNumber': values.phoneNumber,
            'zipCode' : values.zipCode,
            'source': values.source,
            'waterType': values.waterType,
            'landing_page': window.location.origin + window.location.pathname,
            'recaptcha_token' : captchaValue,
            'recaptcha_score' : captchaScore,
            'comments': comments,
          }

          if (activeProspectTestScript === 'nosandbox') {
            const certificateInformation = {
              'certificateLink': values.trustedCertURL,
              'userEmail': values.emailAddress,
              'userPhone': values.phoneNumber,
            }

            try {
              const response = await PostService.retainCertificate('retain-cert', certificateInformation);
              console.log(response);
            } catch (error) {
              console.log(error);
              console.log("Error in retaining the Certificate.");
            }
          }

          try {
            const response = await PostService.postData('lhws-estimate', submittedValues, true);
            if (response.success) {
              setActiveStepIndex(activeStepIndex + 1);

              // Scroll to the Top.
              scrollToTop(formRef);

              // Passing lf.form.success event to DataLayer.
              DataLayerService.formSuccessEvent(form, 'estimate', location);

              // Passing lf.form.start event to DataLayer.
              DataLayerService.formStartEvent(form, location, data);

              localStorage.removeItem("zipCode");
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              actions.setSubmitting(false);
            }
          }
          catch (error) {
            console.log("Error in sending data");
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <Container disableGutters={true} maxWidth={isMobile ? "xs" : "sm"}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              className="flex flex-col justify-center items-start"
              onSubmit={handleSubmit}
              onBlur={
                (event) => {handleOnBlur(event)}
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              {formData.zipCode ? (
                <div className="area-info px-2 pt-4 pb-1 mb-4">
                  <p className="fs-6">
                    We can’t wait for you to join over 1 million satisfied
                    customers!
                  </p>
                </div>
              ) : (
                ""
              )}

              <h2 className="fw-normal">Schedule a Free Water Test</h2>
              {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
              <div className="fw-normal"> Fill out the form below to get started with Leaf Home Water Solutions </div>
              { discountText ? <span className="form-discount-box">Applied Discount: 10% Off</span> : ''}
              <TextInput fieldName={'firstName'} label={'First Name'} placeholder={"E.g., John"} />
              <TextInput fieldName={'lastName'} label={'Last Name'} placeholder={"E.g., Smith"} />
              <EmailInput fieldName={'emailAddress'} />
              <ZipCodeInput
                fieldName={'zipCode'}
                style={{
                  display: formData.zipCode ? "none" : "inline"
                }}
                label={formData.zipCode ? false : true}
              />
              <PhoneNumberInput phoneNumberText="" fieldName={'phoneNumber'} />

              <SMSMarketingCampaignComponent/>
              <WaterTypeSelect></WaterTypeSelect>
              <EstimateSourceSelectLHWS></EstimateSourceSelectLHWS>

              <Field type="hidden" name="trustedCertURL" />
              {reCaptchaShow && (
                <div className="recaptcha">
                  {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                      />
                      <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                    </>
                  )}
                </div>
              )}

              <div>
                <LeafButton
                  buttonType={"primary"}
                  type="submit"
                  onClick={buttonSubmit}
                  isSubmitting={isSubmitting}
                >
                  {isSubmitting ? ("Submitting") : ("Submit")}
                </LeafButton>
              </div>
              <TermAndConditionLHWS buttonText={"Submit"}/>
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default LHWSSingleStepEstimate;
