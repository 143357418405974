import { createContext, useContext, useEffect, useState } from "react";
import InitialStep from "./InitialStep";
import CustomerGroupStep from "./CustomerGroupStep";
import CustomerDetailsStep from "./CustomerDetailsStep";
import ProjectDetailsStep from "./ProjectDetailsStep";
import FinalStep from "./FinalStep";
import ThankYou from "./thankyou";
import ThankYouSelfScheduler from "./thankyouselfscheduler"
import "../../global-style.scss";
import TrustedFormScript from "components/atoms/TrustedFormScript";
import { FormContext } from "../../../../../App";
import ThankYouRenter from "components/atoms/ThankYouRenter";

export const FormContextLHWSMultiStepEstimate = createContext();

const  LHWSMultiStepEstimate = (props) => {
  const { activeLHWSStepIndex, setactiveLHWSStepIndex } = useContext(FormContext);
  const [ rentHome, setRentHome ] = useState(false);
  const selfScheduler = props.dataSet?.selfscheduler ? props.dataSet.selfscheduler : 'disable';
  const formVersion = props.dataSet?.version ? true : false;
  const [selfSchedulerDisable, setSelfSchedulerDisable] = useState(false);
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  const { v2, setV2, setVLeafhome } = useContext(FormContext);
  const [ marketoData, setMarketoData ] = useState({});
  useEffect(() => {
    setV2(formVersion);
    setVLeafhome(leahomeVersion);
  });

  let stepContent;
  switch (activeLHWSStepIndex) {
    case 'group':
      stepContent = <CustomerGroupStep {...props} />;
      break;
    case 'details':
      stepContent = <CustomerDetailsStep {...props} />;
      break;
    case 'project':
      stepContent = <ProjectDetailsStep {...props} />;
      break;
    case 'final':
      stepContent = <FinalStep {...props} />;
      break;
    case 'ThankYou':
      // Option to decide whether we want Self Scheduler or not.
      if (selfScheduler === 'enable' && !rentHome) {
        stepContent = <ThankYouSelfScheduler {...props} />;
      }
      else {
        stepContent = <ThankYou {...props} />;
      }
      break;
    case 'renter':
      stepContent = <ThankYouRenter activeStep={setactiveLHWSStepIndex} {...props} />;
      break;
    default:
      stepContent = <InitialStep {...props} />;
      break;
  }

  return (
    <FormContextLHWSMultiStepEstimate.Provider
      value={{ rentHome, setRentHome, marketoData, setMarketoData, selfSchedulerDisable, setSelfSchedulerDisable }}
    >
      <div className={`leaf-forms leafhome-forms ${v2 ? 'version-2' : ''}`}>{stepContent}</div>
      <TrustedFormScript />
    </FormContextLHWSMultiStepEstimate.Provider>
  );
}

export default LHWSMultiStepEstimate;
