import React, { useState, useContext } from "react";
import { Container, Divider, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { ReactComponent as PhoneIcon } from "components/Forms/form-icons/phone.svg";
import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import AlertMessage from "components/atoms/AlertMessage";
import LeafBox from "components/atoms/LeafBox";
import { useComponentName, useProduct } from "hooks";
import DataLayerService from "utility/dataLayerService";
import { v4 as uuidv4 } from 'uuid';
import { FormContext } from "App";
import SelfSchedulerScript from "components/atoms/SelfSchedulerScript";

const ThankYou = (props) => {
  const phoneNumber = '1-833-376-8129';
  const telPhoneNumber = phoneNumber.replace('-', '');
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const { formData } = useContext(FormContext);
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const selfScheduler = props.dataSet?.selfscheduler ? props.dataSet.selfscheduler : 'disable';
  const zipCode = formData.zipCode;
  const guid = formData.guid;
  const verticalID = 'bDurable';
  const selfSchedulerID = `self-scheduler-${uuidv4()}`;

  const handleCallNow = () => {
    window.location.href = "tel:+" + telPhoneNumber;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 552,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  if (userJourneyTracking) {
    let stepNumber = 4;
    if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
      stepNumber = 5;
    }
    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 4, 'completed');
  }

  const [showProtocolInfo, setShowProtocolInfo] = useState(false);

  const toggleProtocolInfo = () => {
    setShowProtocolInfo(!showProtocolInfo);
  };

  return (
    <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
      <StyledFormContainer>
        { selfScheduler === 'enable' ? <SelfSchedulerScript zipCode={zipCode} guid={guid} verticalID={verticalID} selfSchedulerID={selfSchedulerID} /> : ''}
        <LeafBox style={{width: 'calc(100% - 24px)'}}>
          <AlertMessage message={'Your information has been received!'} severity={'success'} />
        </LeafBox>
        <div className="step5-main-text">
          We'll be calling you soon to schedule a FREE estimate.
        </div>
        <br />

        <p className="step5-sub-text">
          Our estimator will inspect your space, discuss your needs, and offer a custom, no-obligation quote.
        </p>
        <br />

        <p className="step5-sub-text">
          We are committed to your safety.{" "}
          <u onClick={toggleProtocolInfo}>
            Learn about our COVID-19 protocol.
          </u>
        </p>

        {showProtocolInfo && (
          <ul>
            <li>Our team limits contact, including handshakes.</li>
            <li>
              We frequently sanitize and encourage regular washing of
              hands.
            </li>
            <li>
              We practice social distancing and require team members to
              wear masks.
            </li>
          </ul>
        )}

        { selfScheduler === 'enable' ? <div id={selfSchedulerID}></div> : ''}

        <Divider className="divider" />
        <p className="step5-sub-text">
          Can't wait to schedule your free estimate? <br />
          Give us a call at{" "}
          <span className="phone-number" onClick={handleCallNow}>{`${phoneNumber}`}</span>
        </p>
        <LeafBox className="tel-link">
          <LeafButton
            buttonClass='rounded-3 btn-primary w-100 fw-semibold flex'
            type="submit"
            handleClick={handleCallNow}
          >
            <PhoneIcon className="phone-icon" style={{ width: "18px" }}></PhoneIcon>
            <span style={{ marginLeft: "0.5rem" }}>Call Now</span>
          </LeafButton>
        </LeafBox>
        <br />
        <TermAndCondition />
      </StyledFormContainer>
    </Container>
  );
}

export default ThankYou;
